<script setup lang="ts">

import type { VCard } from "vuetify/components";
import type { Testimonial } from "~/types/generalTypes";
import { useDisplay } from "vuetify";

const props = defineProps<{
  testimonial: Testimonial
}>();

const { xs } = useDisplay()

</script>

<template>
  <v-card  v-if="testimonial.active" :width="xs.value ? 242 : 360" class="testimonial ma-4" rounded="xl" variant="flat" color="gray100">
    <v-card-text class="pa-6">
      <v-rating density="compact" disabled color="green500" :model-value="props.testimonial.rating" class="mb-4"/>
      <p class="text-body-1 mb-4">{{ props.testimonial.message }}</p>
      <p class="text-body-1">{{ props.testimonial.userName }}</p>
      <p class="text-body-1">{{ props.testimonial.datePublished }}</p>
    </v-card-text>
  </v-card>
</template>

<style scoped>


</style>
